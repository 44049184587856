var keeps = {};

export const routeGuard = async (to, from) => {
    let keys = Object.keys(keeps)

    if(keys.length == 0){
        return true
    }

    for(let i=0; i<keys.length; i++){
        let k = keys[i]
        let keep = keeps[k]
        let ret = await keep.validate()
        if(!ret){
            return false
        }
    }

    return true
}

export const addKeeper = (obj) => {
    keeps[obj.id] = obj;
    window.onbeforeunload = function(){
        return "You have unsaved changes, are you sure to leave this page?"
    }
}

export const removeKeeper = (obj) => {
    delete keeps[obj.id];
    if(Object.keys(keeps).length == 0){
        window.onbeforeunload = null
    }
}
